<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 新增人员</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="姓名：" prop="full_name">
          <el-input
            v-model="form.full_name" maxlength="15"
            placeholder="请输入姓名"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            v-model="form.mobile"
            placeholder="请输入手机号" maxlength="11"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="部门：" prop="department_id">
          <el-cascader style="width:300px" v-model="form.department_id" :options="list"
              :props="{ expandTrigger: 'hover',checkStrictly: true,emitPath:false,value:'id',label:'title',children:'_child'}"
              clearable></el-cascader>
        </el-form-item>
        <el-form-item label="职位：">
          <el-select
            v-model="form.position_id"
            placeholder="请选择"
            style="width:300px" filterable
          >
            <el-option :label="item.title" v-for="(item,index) in position_list" :key="index" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：">
            <el-input-number v-model="form.sort" controls-position="right" :min="0" />
        </el-form-item>
        <el-form-item label="审核人员：">
          <el-checkbox v-model="is_purchase" label="采购部"></el-checkbox>
          <el-checkbox v-model="is_salesman" label="业务部"></el-checkbox>
          <el-checkbox v-model="is_quality" label="质管部"></el-checkbox>
          <el-checkbox v-model="is_sale" label="销售部"></el-checkbox>
          <el-checkbox v-model="is_president" label="总经理"></el-checkbox>
        </el-form-item>
        
        <el-form-item label="身份证：">
          <el-input
            v-model="form.id_card"
            placeholder="请输入身份证" maxlength="18"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="住址：">
          <el-input
            v-model="form.address"
            placeholder="请输入住址" maxlength="30"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="照片:" prop="photo">
          <SigleUpload :img="form.photo" accept="image/*" type="picture-card" formkey="photo" form="form" @PicturePreview="PicturePreview" @handleUploadSuccess="handleUploadSuccess" @handleUploadRemove="handleUploadRemove"></SigleUpload>
        </el-form-item>
        
        
        <el-form-item label="登录用户名：" prop="username">
          <el-input
            v-model="form.username" maxlength="20"
            placeholder="请输入用户名"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="登录密码：" prop="password">
          <el-input
            type="password" show-password 
            v-model="form.password"
            placeholder="请输入登录密码"
            style="width:300px"
          />
        </el-form-item>
        
      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
  <!-- 上传图片预览 -->
  <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
    <img style="width: 100%;" :src="uploadImageUrl" alt="">
  </el-dialog>
</template>

<script>
// 上传组件
import SigleUpload from "@/components/sigleUpload";
export default {
  name: "addPersonnel",
  components: {SigleUpload},
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
         sort:0,
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空",trigger: "blur"}
        ],
        password: [
          { required: true, message: "密码不能为空",trigger: "blur"}
        ],
        full_name: [
          { required: true, message: "姓名不能为空",trigger: "blur"}
        ],
        mobile: [
          { required: true, message: "手机号不能为空",trigger: "blur"}
        ],
        department_id: [
          { required: true, message: "部门不能为空",trigger: "blur"}
        ]
      },
      list: [],
      position_list:[],
      dialogVisible:false,
      
      
      // 上传图片
      uploadImageUrl: '',
      uploadImgVisible: false,
      is_salesman:false,
      is_quality:false,
      is_sale:false,
      is_president:false,
      is_purchase:false
    };
  },
  
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          this.rules.password[0].required=false;
          this.get_data()
      }
      this.getOptions();
  },
  methods: {
      // 表单重置
      reset(pid) {
        this.form = {
          title:'',
          remark:'',
          pid:pid || 0,
          sort:0,
          is_salesman:false,
          is_quality:false,
          is_sale:false,
          is_president:false,
          is_purchase:false
        };
        if(this.$refs['form']){
          this.$refs['form'].clearValidate();
        }
      },
      /** 提交按钮 */
      submitForm: function(form) {
        this.$refs[form].validate((valid)  => {
          if (valid) {
            this.is_ajax=true;
            if(form=='form'){
              if(this.form.id){
                let form_data={...this.form};
                if(!form_data.password){
                  // form_data.enable_password
                    form_data.password=''
                }
                form_data.is_salesman=this.is_salesman?1:0;
                form_data.is_quality=this.is_quality?1:0;
                form_data.is_sale=this.is_sale?1:0;
                form_data.is_president=this.is_president?1:0;
                form_data.is_purchase=this.is_purchase?1:0;
                this.$httpPost("/backend/sys.Admin/update", form_data).then((res) => {
                    if (res.status == 200) {
                        this.$message.success('更新成功');
                        this.$router.go(-1)
                    } else {
                        this.$message.error(res.message);
                    }
                    this.is_ajax=false;
                }).catch((err) => {
                    console.log(err);
                    this.is_ajax=false;
                });
              }else{
                  this.$httpPost("/backend/sys.Admin/save", this.form).then((res) => {
                      if (res.status == 200) {
                          this.$message.success('新增成功');
                          // this.reset();
                          this.$router.go(-1)
                      } else {
                          this.$message.error(res.message);
                      }
                      this.is_ajax=false;
                  }).catch((err) => {
                      console.log(err);
                      this.is_ajax=false;
                  });
              }
            }
          }
        });
      },
      get_data(){
          this.$httpGet("/backend/sys.Admin/edit", {id:this.id}).then((res) => {
              if (res.status == 200) {
                  res.data.password=undefined;
                  let _data=res.data
                  this.is_salesman=_data.is_salesman==1?true:false;
                  this.is_quality=_data.is_quality==1?true:false;
                  this.is_sale=_data.is_sale==1?true:false;
                  this.is_president=_data.is_president==1?true:false;
                  this.is_purchase=_data.is_purchase==1?true:false;
                  this.form=_data;
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
      getOptions() {
          this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
              if (res.status == 200) {
                  this.list = res.data.department_list;
                  this.position_list=res.data.position_list
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
      // 上传图片--s
      PicturePreview(file) {
        this.uploadImageUrl = file.url;
        this.uploadImgVisible = true;
      },
      handleUploadSuccess(res){
        this[res.form][res.key]=res.url
      },
      handleUploadRemove(res){
        this[res.form][res.key]=''
      },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  width: 50%;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
::v-deep .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>
